.App {
  text-align: center;
  overflow-x: hidden;
}

.colored-text {
  color: #F89187;
}

.colored-text:hover {
  text-shadow: none;
  color: #F89212;
}

.framework-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 75px;
  column-gap: 10px;
}

.proj-img {
  height: 12.5rem;
  width: 99.5%;
  object-position: top;
  margin-top: 0.3rem;
  object-fit: cover;
  border: 0.0625rem solid blue;
  border-radius: 0.7rem;
}

.proj-img:hover {
  transform: translateY(-0.18rem);
}

.exp-img-nolink {
  max-height: 2rem;
  max-width: 2rem;
  border: 0.0625rem solid blue;
  border-radius: 0.3rem;
}

.vol-img {
  max-height: 2rem;
  max-width: 2rem;
  border: 0.0625rem solid blue;
  border-radius: 0.3rem;
}

.vol-img:hover {
  transform: translateY(-0.18rem);
}

.exp-img {
  max-height: 2rem;
  max-width: 2rem;
  border: 0.0625rem solid blue;
  border-radius: 0.3rem;
}

.exp-img:hover {
  transform: translateY(-0.18rem);
}

.proj-img-top {
  max-height: 15.625rem;
  max-width: 15.625rem;
  border: 0.0625rem solid blue;
  border-radius: 0.97rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}

@media (max-width: 88rem) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.3rem;
    justify-content: center;
    align-items: center;
  }
}

.grid-projimg-item {
  align-items: center;
  justify-content: center;
  animation: 1.5s 1 alternate grow;
}

@media (max-width: 48rem) {
  .grid-projimg-item {
    display: grid;
    width: 6.8rem;
    height: 6.8rem;
    align-items: center;
    justify-content: center;
  }
}

.grid-projimg-item:hover {
  transform: translateY(-0.18rem);
}

a, h1, h2, h3, h4, h5, h6, p {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  animation: 2.5s 1 alternate grow;
}

@keyframes grow {
  from {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(1, 1);
  }
}

a:hover {
  text-shadow: 0.12rem 0.187rem 0.187rem teal;
}

.my-image {
  max-width: 5rem;
  border-radius: 2.5rem;
  animation: .75s 1 alternate bounce;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.6rem);
  }
}

.App-header {
  background-color: rgb(3, 7, 18);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: white;
}

.portfolio-main-container {
  width: 45%;
  max-width: 45%;
  padding-top: 1.56rem;
  padding-bottom: 4.687rem;
  background-color: rgb(15, 24, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 110rem) {
  .portfolio-main-container {
    width: 70%;
    max-width: 70%;
    padding-top: 1.56rem;
    padding-bottom: 4.687rem;
    background-color: rgb(15, 24, 50);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 50rem) {
  .portfolio-main-container {
    width: 97%;
    max-width: 97%;
    padding-top: 1.56rem;
    padding-bottom: 4.687rem;
    background-color: rgb(15, 24, 50);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.exp-container-main {
  width: 90%;
  padding-bottom: 0.62rem;
  background-color: rgba(59, 84, 158, 0.048);
  display: flex;
  flex-direction: column;
  animation: 1.5s 1 alternate grow;
}

.exp-container-main:hover {
  box-shadow: 0 0.0625rem 0.125rem blue;
  transform: translateY(-0.0625rem);
}

.exp-container-secondary {
  width: 90%;
  padding-bottom: 0.62rem;
  background-color: rgba(59, 84, 158, 0);
  display: flex;
  flex-direction: column;
  animation: 1.5s 1 alternate grow;
}

.exp-container-secondary:hover {
  background-color: rgba(59, 84, 158, 0.030);
}

.exp-type {
  text-align: left;
  margin: 0.62rem 4.687rem 0;
  font-size: 0.8rem;
}

.exp-type-2 {
  text-align: left;
  margin: 0.62rem 0.62rem 0;
  font-size: 0.8rem;
}

.exp-title {
  text-align: left;
  margin: 0.62rem 4.687rem;
  font-size: 1.062rem;
}

.exp-description {
  text-align: left;
  padding-bottom: 0.5rem;
  margin: 0 4.687rem;
  font-size: 0.8rem;
}

@media (max-width: 47.93rem) {
  .exp-description {
    text-align: left;
    padding-bottom: 0.5rem;
    margin: 0 0.937rem;
    font-size: 0.8rem;
  }
}

.volunteer-container {
  display: flex;
  width: 90%;
  justify-content: space-between;
  animation: 1.5s 1 alternate grow;
}

.volunteer-container:hover {
  transform: translateY(-0.125rem);
  box-shadow: 0 0.0625rem 0.125rem blue;
}

.test-cont-left, .test-cont-right {
  flex: 1;
  background-color: rgba(59, 84, 158, 0.048);
  padding: 0.62rem;
}

.flex-title {
  text-align: left;
  margin: 0.62rem 0.62rem;
  font-size: 1.1rem;
}

.flex-description {
  text-align: left;
  padding-bottom: 0.5rem;
  margin: 0 0.62rem;
  font-size: 0.8rem;
}

.github-link:hover {
  text-shadow: 0.3rem 0.3rem 0.3rem blue;
}

.avail-for-work {
  height: 2.28rem;
  border: 0.125rem solid rgb(0, 0, 139);
  border-radius: 3.125rem;
  padding: 0.437rem;
  cursor: pointer;
  margin-left: 0.62rem;
}

.avail-for-work:hover {
  transform: translateY(-0.125rem);
  box-shadow: 0.187rem 0.312rem 0.5rem rgb(0, 0, 139);
}

.img-container {
  width: 45%;
  padding: 0.187rem;
}

.left-align {
  text-align: left;
  padding-left: 1.875rem;
}

.exp-title-fonts {
  font-weight: 750;
  text-align: left;
  font-size: 1.25rem;
  width: 90%;
}

@media (max-width: 48rem) {
  .notproj-title-fonts {
    padding-top: 2.687rem;
    font-weight: 750;
    text-align: left;
    font-size: 1.25rem;
    width: 90%;
  }
}

@media (min-width: 48rem) {
  .notproj-title-fonts {
    font-weight: 750;
    text-align: left;
    font-size: 1.25rem;
    width: 90%;
  }
}

.favproj-title-fonts {
  font-weight: 750;
  text-align: left;
  font-size: 1.25rem;
  width: 90%;
}

.volunteer-title-fonts {
  font-weight: 750;
  text-align: left;
  font-size: 1.25rem;
  padding-left: 0.937rem;
  width: 90%;
}

.exp-title-description {
  text-align: left;
  font-weight: 100;
  font-size: 0.8rem;
}

.title-fonts {
  font-weight: 750;
  text-align: center;
}

.description-fonts {
  font-weight: 100;
  padding-right: 0.312rem;
  padding-left: 0.312rem;
  text-align: center;
  font-size: 1rem;
}

.top-description {
  font-weight: 100;
  padding-right: 0.312rem;
  padding-left: 0.312rem;
  text-align: center;
  font-size: 0.8rem;
}
